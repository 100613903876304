import { AccountState } from './types';

export const isLoggedIn = (state: AccountState) => Boolean(state.token);

export const userInfo = (state: AccountState) => {
  return {
    email: state.userName,
    company: state.company,
    isAdmin: state.groups.includes('Admins'),
    subscription: state.organizationSubscription,
    firstName: state.firstName,
    lastName: state.lastName,
    newsLetter: state.newsLetter,
  };
};

export const subscriptionInfo = (state: AccountState) => {
  return {
    subscription: state.organizationSubscription,
    company: state.company,
    kvkNr: state.kvkNr,
    ibanName: state.ibanName,
    ibanNr: state.ibanNr,
    incassoAccepted: state.incassoAccepted,
    daysTillEnd: state.daysTillEnd,
    inlenerWebModule: state.inlenerWebModule,
    caoTypes: state.caoTypes,
  };
};

export const pdfAllowed = (state: AccountState) => {
  return Boolean(state.token) && (state.organizationSubscription === 'monthly_big' ||
    state.organizationSubscription === 'monthly_middle' ||
    state.organizationSubscription === 'trial');
};

export const trialEnded = (state: AccountState) => {
  return !(state.daysTillEnd > 0 && state.daysTillEnd !== -1) && state.organizationSubscription === 'trial';
};

export const trialDue = (state: AccountState) => {
  const due =
  (state.organizationSubscription === 'trial' &&
  state.daysTillEnd > 0 && state.daysTillEnd < 8) ? state.daysTillEnd : false;
  return due;
};
