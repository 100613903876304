export interface ICao {
  avv: boolean;
  cao_id: string;
  cao_entry_date: string | null;
  website_text: string;
  cao_url: string;
  duration_end: string;
  duration_start: string;
  minimumwage_date: string;
  readable_name: string;
  readable_name_year: string;
  salary_tables: string[];
  short_name: string;
  year_end_bonus_type: string;
  year_end_bonus_amount: string;
  updated_at?: string;
  kvk_inlener?: string;
  company_name?: string;
}

export class Cao implements ICao {
  public cao_entry_date = null;
  public avv = false;
  public cao_id = '';
  public website_text = '';
  public cao_url = '';
  public duration_end = '';
  public duration_start = '';
  public minimumwage_date = '';
  public readable_name = '';
  public readable_name_year = '';
  public salary_tables = [];
  public short_name = '';
  public year_end_bonus_type = '';
  public year_end_bonus_amount = '';
  [key: string]: any;
}
