interface IJob {
  job_id: number;
  name: string;
  payscales: string[];
  minimal_payscale: string;
}

export class Job implements IJob {
  public job_id = 0;
  public name = '';
  public payscales = [];
  public minimal_payscale = '';
  public aard = '';
}
