import { MainState } from './types';
import { Cao } from '@/interfaces/cao';
import { ToolResult } from '@/interfaces/toolResult';
import { NewsItem } from '@/interfaces/NewsItem';

export const SET_LAST_TOOLRESULT = (state: MainState, result: ToolResult) => {
  state.lastToolResult = result;
};

export const SET_CAOS = (state: MainState, caos: Array<{ value: Cao, text: string }>) => {
  state.caos = caos;
};

export const SET_NEWS = (state: MainState, news: NewsItem[]) => {
  state.news = news;
};

export const SET_VERHOGINGEN = (state: MainState, verhogingen: string[][]) => {
  state.verhogingen =  verhogingen;
};

export const ACCEPT_TERMS = (state: MainState) => state.termsAccepted = true;
