import { MainState } from './types';
import { ToolResult } from '@/interfaces/toolResult';

const state: MainState = {
  lastToolResult: new ToolResult(),
  caos: null,
  termsAccepted: false,
  news: [],
  verhogingen: [],
};

export default state;
