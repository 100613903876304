import { ICao, Cao } from './cao';
import { ISalaryTable, SalaryTable } from './salaryTable';
import { Job } from './job';

interface IToolResult {
  cao: ICao;
  salaryTableName: string;
  payscale: string;
  job: Job;
  birthdate: string;
  fromDate: string;
  adv_perc: number;
  salary: number;
  periodical: string | null;
  adv: boolean;
  abuNbbu: boolean;
  workweek: number | null;
  showYearEndBonus: boolean;
  inlenerCao: boolean;
  wagesPer: string;
  wagesDividedBy: number;
  activePer: string;
  activePerDescription?: string;
}

export class ToolResult implements IToolResult {
  public cao = new Cao();
  public salaryTable = new SalaryTable();
  public payscale = '';
  public job = new Job();
  public birthdate = '';
  public fromDate = '';
  public adv_perc = 0;
  public salary = 0;
  public periodical = null;
  public adv = false;
  public abuNbbu = false;
  public workweek = null;
  public showYearEndBonus = false;
  public inlenerCao = false;
  public wagesPer = '';
  public wagesDividedBy = -1;
  public activePer = '';
  public salaryTableName = '';
}
