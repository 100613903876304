import Vue from 'vue';
import { Module } from 'vuex';
import { actions } from './actions';
import Vuex from 'vuex';
import { RootState } from '../types';

Vue.use(Vuex);

const namespaced: boolean = true;

export const inleners: Module<{}, RootState> = {
  namespaced,
  actions,
};
