import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { main } from './main/index';
import { account } from './account/index';
import { inleners } from './inleners/index';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: RootState) => ({
    account: state.account,
  }),
});

Vue.use(Vuex);

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
    main: main.state,
    account: account.state,
  },
  modules: {
    main,
    account,
    inleners,
  },
  plugins: [
    vuexLocal.plugin,
  ],
};

export default new Vuex.Store<RootState>(storeOptions);
