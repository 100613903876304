







import { Component, Prop, Vue } from 'vue-property-decorator';
import { setTimeout } from 'timers';
import { BButton } from 'bootstrap-vue';

@Component({
  components: { BButton },
})
export default class VButton extends Vue {

  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: false }) private busy!: boolean;
  private pressed: boolean = false;

  private onClick() {
    this.pressed = true;
    setTimeout(() => {
      this.pressed = false;
      this.$emit('click');
    }, 100);
  }
}
